import { FC } from 'react';
// Interfaces
import { IconSvgProps } from '@utmb/types/icons';

const Strava: FC<IconSvgProps> = ({ color, clss }) => {
    return (
        <svg viewBox="0 0 16 21">
            <path
                d="m10.569 15.464 1.814-3.52H8.727l1.842 3.52M6.178 0c.078.142.13.234.18.327 1.97 3.764 3.943 7.527 5.907 11.295.101.195.21.271.434.267.808-.013 1.617-.005 2.501-.005L10.582 20.8l-4.657-8.897h2.774L6.203 7.127c-.697 1.347-1.37 2.65-2.046 3.952-.145.28-.235.657-.471.804-.226.14-.608.043-.921.043-.901.002-1.802.001-2.765.001L6.178 0"
                fill={color}
                fillRule="evenodd"
            />
        </svg>
    );
};

export default Strava;
